import React, { useState, useMemo, useEffect } from "react";
import { fetchPacientes } from "../services/firebase/pacientes";
import { useSelector } from "react-redux";
export const useBuscarPacientes = () => {
  const [query, setQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pacientes, setPacientes] = useState([])
  const empresa = useSelector((state) => state.empresa);
  // Debounce function
  const debounce = (fn, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };

  // Fetch data from the backend API
  const fetchData = async (query) => {
    try {
      setLoading(true)
      const pacientes = await fetchPacientes(empresa.id);
      
      const filtroPacientes = pacientes.filter((item) => {
        return `${item.nombres} ${item.apellidos} ${item.numDoc}`
        .toLocaleLowerCase()
        .includes(query.toLocaleLowerCase());
      })
      setFilteredItems(filtroPacientes)
      // setPacientes(filtroPacientes);
      setLoading(false)
      
    } catch (error) {
      console.error("Error fetching data:", error);
    } 
  };

  // Update filtered items with a delay (e.g., 300ms) after the query changes
  useEffect(() => {
    const delayedFiltering = debounce(() => {
      if (query.length > 0) {
        
        fetchData(query);
      } else{
        clearFilteredItems([])
      }
    }, 300); // Adjust the delay as needed (e.g., 300ms)

    delayedFiltering();

    // Clean up the timeout on component unmount
    return () => clearTimeout(delayedFiltering);
  }, [query]);

  const clearFilteredItems = () => {
    setFilteredItems([]);
  };

  return { query, setQuery, filteredItems, clearFilteredItems, loading };
};
